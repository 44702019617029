<template>
    <div>
        <b-card class="p-2">
            <div class="d-flex justify-content-between align-items-end" style="gap: 10px">
                <div>
                    <h1 style="font-size: 2.5rem;">Cluster 1</h1>

                    <h4 v-if="user_type === 'operations'">Manager Name</h4>
                    <v-select v-else :options="managerOptions" multiple placeholder="Choose A Manager" label="label"
                        class="mt-1" style="width: 250px;" />

                </div>
                <div class="d-flex border-primary bg-light-primary rounded-lg w-75">
                    <div class="d-flex flex-column align-items-center justify-content-center p-1 px-2 w-100"
                        style="gap: 5px">
                        <p class="text-center text-nowrap" style="font-size: small;">Total Students</p>
                        <h4>542</h4>
                    </div>
                    <div class="border-primary"></div>
                    <div class="d-flex flex-column align-items-center justify-content-center p-1 px-2 w-100"
                        style="gap: 5px">
                        <p class="text-center text-nowrap" style="font-size: small;">Converted Students</p>
                        <h4>342</h4>
                    </div>
                    <div class="border-primary"></div>
                    <div class="d-flex flex-column align-items-center justify-content-center p-1 px-2 w-100"
                        style="gap: 5px">
                        <p class="text-center text-nowrap" style="font-size: small;">Conditional Offer</p>
                        <h4>234</h4>
                    </div>
                    <div class="border-primary"></div>
                    <div class="d-flex flex-column align-items-center justify-content-center p-1 px-2 w-100"
                        style="gap: 5px">
                        <p class="text-center text-nowrap" style="font-size: small;">Unconditional Offer</p>
                        <h4>32</h4>
                    </div>
                    <div class="border-primary"></div>
                    <div class="d-flex flex-column align-items-center justify-content-center p-1 px-2 w-100"
                        style="gap: 5px">
                        <p class="text-center text-nowrap" style="font-size: small;">Visa</p>
                        <h4>{{ visaCount }}</h4>
                    </div>
                    <div class="border-primary"></div>
                    <div class="d-flex flex-column align-items-center justify-content-center p-1 px-2 w-100"
                        style="gap: 5px">
                        <p class="text-center text-nowrap" style="font-size: small;">Packages Sold</p>
                        <h4>{{ packagesSoldCount }}</h4>
                    </div>

                </div>


            </div>

            <hr class="mb-3 mt-3" />

            <!-- Packages Sold Progress Bar -->
            <div class="d-flex align-items-end" style="gap: 20px">
                <div class="w-100 ">
                    <div class="d-flex justify-content-between" style="margin-bottom: 8px;">
                        <h4>Packages Sold — <span style="color: #999"> Total {{ packagesSoldCount }} </span></h4>
                        <div class="d-flex align-items-center" style="gap: 10px;">
                            <b-button variant="primary rounded-pill" style="padding: 2px">
                                <feather-icon icon="ChevronLeftIcon" size="16" />
                            </b-button>
                            <div>
                                <h6 class="m-0">July 2024</h6>
                            </div>
                            <b-button variant="primary rounded-pill" style="padding: 2px">
                                <feather-icon icon="ChevronRightIcon" size="16" />
                            </b-button>
                        </div>
                    </div>
                    <div class="d-flex w-100" style="height: 50px;">
                        <div class="w-100 h-100 d-flex overflow-hidden rounded-lg">
                            <!-- <div class="bg-primary h-100" :style="'width: ' + 50 + '%'">&nbsp;</div> -->
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">0 - 10</h6>
                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #808eff"
                                    :style="'width: ' + getProgress(0, 10, packagesSoldCount) + '%; background-color: ' + color1">
                                </div>

                            </div>
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">11 - 50</h6>
                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #4d61ff"
                                    :style="'width: ' + getProgress(11, 50, packagesSoldCount) + '%; background-color: ' + color2">
                                </div>
                                <!-- 11 - 50 -->
                            </div>
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">51 - 100</h6>

                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #1a34ff"
                                    :style="'width: ' + getProgress(51, 100, packagesSoldCount) + '%; background-color: ' + color3">
                                </div>

                            </div>
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">101 - 200</h6>
                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #001be6"
                                    :style="'width: ' + getProgress(101, 200, packagesSoldCount) + '%; background-color: ' + color4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-end p-1 pl-3 rounded-lg bg-light" style="width: 280px;">
                    <h4>Earnings</h4>
                    <h1 style="font-size: 2rem;" class="m-0">₹98070</h1>
                </div>
            </div>

            <hr class="mb-3 mt-3" v-if="user_type !== 'operations'" />

            <!-- Visa Progress Bar -->
            <div class="d-flex align-items-end" style="gap: 20px" v-if="user_type !== 'operations'">
                <div class="w-100 ">
                    <div class="d-flex justify-content-between" style="margin-bottom: 8px;">
                        <h4>Visa — <span style="color: #999"> Total {{ visaCount }} </span></h4>
                        <div class="d-flex align-items-center" style="gap: 10px;">
                            <b-button variant="primary rounded-pill" style="padding: 2px">
                                <feather-icon icon="ChevronLeftIcon" size="16" />
                            </b-button>
                            <div>
                                <h6 class="m-0">2024</h6>
                            </div>
                            <b-button variant="primary rounded-pill" style="padding: 2px">
                                <feather-icon icon="ChevronRightIcon" size="16" />
                            </b-button>
                        </div>
                    </div>
                    <div class="d-flex w-100" style="height: 50px;">
                        <div class="w-100 h-100 d-flex overflow-hidden rounded-lg">
                            <!-- <div class="bg-primary h-100" :style="'width: ' + 50 + '%'">&nbsp;</div> -->
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">0 - 100</h6>
                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #808eff"
                                    :style="'width: ' + getProgress(0, 100, visaCount) + '%; background-color: ' + color1">
                                </div>

                            </div>
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">101 - 500</h6>
                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #4d61ff"
                                    :style="'width: ' + getProgress(101, 500, visaCount) + '%; background-color: ' + color2">
                                </div>
                                <!-- 11 - 50 -->
                            </div>
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">501 - 1000</h6>

                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #1a34ff"
                                    :style="'width: ' + getProgress(501, 1000, visaCount) + '%; background-color: ' + color3">
                                </div>

                            </div>
                            <div class="w-25 h-100 border position-relative bg-light">
                                <div class="z-50"
                                    style="position: absolute;  top: 50%; left: 50%; transform: translate(-50%, -50%);">
                                    <b-badge variant="primary">
                                        <h6 class="text-white m-0">1000 - 1500</h6>
                                    </b-badge>
                                </div>
                                <div class="h-100" style="background-color: #001be6"
                                    :style="'width: ' + getProgress(1000, 1500, visaCount) + '%; background-color: ' + color4">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-end p-1 pl-3 rounded-lg bg-light" style="width: 280px;">
                    <h4>Earnings</h4>
                    <h1 style="font-size: 2rem;" class="m-0">₹45070</h1>
                </div>
            </div>

            <!-- <hr class="mb-3 mt-3" /> -->

            <!-- <div>
                <h4>Franchises</h4>
                <v-select :disabled="role === 'operations'" v-model="selectedFranchiseOpt" :options="franchiseOpt"
                    multiple placeholder="Choose Franchises" label="label" class="mt-1">

                    <template #option="data">
                        <div style="width: 180px;">
                            <b-badge variant="primary">
                                {{ data.label }}
                            </b-badge>
                        </div>
                    </template>
<template #selected-option="data">
                        <b-badge variant="primary">
                            {{ data.label }}
                        </b-badge>
                    </template>

</v-select>
</div> -->
        </b-card>
    </div>
</template>
<script>
import Counts from '@/views/components/StaffHomeComponents/Counts.vue';
import { BBadge, BButton, BCard } from 'bootstrap-vue';
import vSelect from "vue-select";
import store from "@/store";

export default {
    components: {
        BCard,
        Counts,
        vSelect,
        BBadge,
        BButton
    },
    data() {
        return {
            packagesSoldCount: 150,
            visaCount: 750,
            // user_type: 'operations',  // manager, senior_manager, operations
            studentCounts: [
                { label: 'Total Students', value: 99 },
                { label: 'Converted Students', value: 99 },
                { label: 'Conditional Offer', value: 99 },
                { label: 'Unconditional Offer', value: 99 },
                { label: 'Visa', value: 99 },
                { label: 'Joined', value: 99 },
            ],
            managerOptions: [
                { label: 'Manager 1', value: 'option1' },
                { label: 'Manager 2', value: 'option2' },
                { label: 'Manager 3', value: 'option3' },
                { label: 'Manager 4', value: 'option4' },
            ],
            franchiseOpt: [
                { label: 'Franchise 1', value: 'option1' },
                { label: 'Franchise 2', value: 'option2' },
                { label: 'Franchise 3', value: 'option3' },
                { label: 'Franchise 4', value: 'option4' },
            ],
            selectedFranchiseOpt: [
                { label: 'Franchise 1', value: 'option1' },
                { label: 'Franchise 2', value: 'option2' },
                { label: 'Franchise 3', value: 'option3' },
                { label: 'Franchise 4', value: 'option4' },
            ],
            currentYear: 2024,
            count: 51,

            color1: '#7a8aff',
            color2: '#6678ff',
            color3: '#5266ff',
            color4: '#3d54ff',

            // color1: '#9dffe6',
            // color2: '#76ffdd',
            // color3: '#4effd3',
            // color4: '#27ffc9',

        }
    },
    computed: {
        user_type() {
            if (store.getters["user/getUserDetails"].user_type) {
                let type = store.getters["user/getUserDetails"].user_type;
                return type;
            } else {
                return null;
            }
        },
    },
    methods: {
        getProgress(lowerLimit, upperLimit, item) {
            if (item > upperLimit) return 100;
            if (item < lowerLimit) return 0;

            let percentage = ((item - lowerLimit) / (upperLimit - lowerLimit)) * 100
            return percentage
        },
        previousYear() {
            this.currentYear--;
            // Logic to update progress bar values based on the year can go here
        },
        nextYear() {
            this.currentYear++;
            // Logic to update progress bar values based on the year can go here
        },
    },
}
</script>
<style>
.z-50 {
    z-index: 50;
}

.progress-bar-container {
    display: flex;
    height: 100px;
    width: 500px;
    border: 1px solid #ccc;
    overflow: hidden;
}

/* Progress bar sections with colors */
.progress-bar-section {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    color: black;
    background-color: white;
    /* Default to white */
    border-right: 1px solid #ddd;
    /* Optional: to visually separate sections */
}

/* Active state based on conditions */
.red {
    background-color: red !important;
}

.orange {
    background-color: orange !important;
}

.green {
    background-color: green !important;
}

/* Arrow button styles */
.arrow-container {
    display: flex;
    align-items: center;
}

.arrow-button {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    outline: none;
    transition: transform 0.2s;
}

.arrow-button:hover {
    transform: scale(1.1);
}
</style>
